<template>
  <v-container>
    <v-row>
      <v-col >
        <v-form>
          <v-text-field 
            outline
            label="First Name"
            type="text"
            v-model="User.FirstName"
            :rules="textRules"
          ></v-text-field>
          <v-text-field
            outline label="Last Name"
            type="text"
            v-model="User.LastName"
            :rules="textRules"
          ></v-text-field>
          <v-text-field
            outline
            label="email"
            type="text"
            v-model="User.email"
            :rules="emailRules"
          ></v-text-field>
          <v-text-field
            outline
            label="Password*"
            type="password"
            v-model="User.password"
            :rules="passwordRules"
          ></v-text-field>
          <v-text-field
            outline
            label="Confirm Password"
            type="password"
            v-model="passwordConfirm"
            :rules="[v => (v === User.password) || `Your passwords do not match`]"
          ></v-text-field>
          <p>*Passwords must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character and be at least 8 characters long.</p>
           <v-switch v-model="localUserType"  class="ma-2" label="Camp Manager"></v-switch>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          outline
          v-if="localUserType"
          label="Camp Name"
          type="text"
          v-model="camp.campName"
          :rules="textRules"
        ></v-text-field>
        <v-text-field
          outline
          v-if="localUserType"
          label="Camp URL"
          type="text"
          v-model="camp.campURL"
          :rules="urlRules"
      ></v-text-field>
      </v-col>
    </v-row>
      <v-row  class="mb-6"
        justify="center"
        no-gutters
      >
        <v-col class="text-center" >
          <v-checkbox
            v-model="localAcceptTerms"
            @change="$emit('update_accept_terms', localAcceptTerms);"
          >
            <template  v-slot:label>
              <span>I accept the <a @click="showTermsAndConditions=!showTermsAndConditions"> terms and conditions</a></span>
            </template>
          </v-checkbox>
        </v-col>
        <v-row v-if="!showTermsAndConditions">
          <v-col :cols=2 class="pa-2">
            <v-btn @click="showTermsAndConditions=!showTermsAndConditions"> Hide</v-btn> 
          </v-col>
          <v-col :cols=12 class="pa-2">
            <TermsAndConditions></TermsAndConditions>
          </v-col>
        </v-row>
        </v-row>
        <v-row>
          <v-col :cols=2>
            <v-icon color="primary"  style="{ display: 'block' }" large depressed @click="$emit('back')">mdi-chevron-left</v-icon>
          </v-col>
          <v-col md="auto" :cols=3>
            <v-btn v-on:click="$emit('register')" small color="primary" :disabled="isDisabled">Register</v-btn>
          </v-col>
        </v-row>
  </v-container>
</template>

<script>
import TermsAndConditions from '@/components/TermsAndConditions.vue'

export default {
  name: 'Register',
  components: {
    'TermsAndConditions': TermsAndConditions
  },
  props: ['User',  'acceptTerms', 'camp', 'isManager'],
  data () {
    return {
       showTermsAndConditions: true,
       passwordConfirm: null,
       passwordsMatch: false,
       localAcceptTerms: false,
       localUserType: false,
       emailRules: [
         v => (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v)) ||
              `Please enter a valid email`
       ],
       textRules: [
         v => (!!v) ||
              `Please enter a value`
       ],
       passwordRules: [
         v => (/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(v)) 
         || `Please enter a password that contains at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character and is at least 8 characters long.`
       ],
       urlRules: [
         v => (/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(v)) || 'Please enter a valid url'
       ]
    }
  },
  mounted() {
    this.localUserType = this.isManager
  },
  computed: {
    isDisabled() {
      let disabled = true
     
      if(this.localAcceptTerms && this.approvedPassword) {disabled = false}
      return disabled
    },
    approvedPassword() {
      let approved = false
      let passwordsMatch = false
      let strongPassword = false
      if(this.User.password === this.passwordConfirm) {passwordsMatch = true}
      // The string must contain at least 1 lowercase alphabetical character
      // The string must contain at least 1 uppercase alphabetical character
      // The string must contain at least 1 numeric character
      //The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
      // The string must be eight characters or longer
      if(this.User.password.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")) {strongPassword = true}
      if(passwordsMatch && strongPassword) {approved = true }
      return approved
    },
    },

    
  watch: {
    localUserType(newVal) {
      let newUserType = 'parent'
      if(newVal) {
        newUserType = 'camp'
      }
      this.$emit('updateUserType', newUserType)
    }
  }
}
</script>

<style scoped>

</style>