<template>
  <div class="login">
    <v-container>
      <v-spacer></v-spacer>
         <v-img
          src="../img/logoDemo1png.png"
          height="150"
          width="300"
        ></v-img>
        <div v-if="register">
          <Register 
            :user="user"
            :camp="camp"
            :isManager="isManager"
            @register="RegisterAccount()"
            @back="()=>{ $emit('toggle-register', false) }"
            @updateUserType="updateUserType"
            :acceptTerms="acceptTerms" @update_accept_terms="acceptTerms=$event"
          ></Register>
        </div>
        <div v-else-if="resetPassword">
          <ResetPasswordEmail
            @resetPasswordEmail="resetPasswordEmail"
            @goBack="resetPassword=false"
          >
          </ResetPasswordEmail>
        </div>
        <div v-else-if="resetPasswordEmailSent">
          <p>If you have an account in our system, you will recieve a link via email to reset your password.</p>
           <div class="my-2">
              <v-btn v-on:click="closeResetPassword" small>Close</v-btn>
            </div>

        </div>
        <div v-else>
          <Login 
            :username="username" @updateusername="username=$event"
            :password="password" @updatepassword="password=$event"
            :loginMsg="loginMsg"
          ></Login>
          <v-row>
            <v-col md="auto">
              <div class="my-2">
                <v-btn v-on:click="login" :disabled="username==='' || password=== ''" small>Login</v-btn>
              </div>
            </v-col>
            <v-col md="auto">
              <div class="my-2">
                <v-btn v-on:click="$emit('toggle-register', true)" small>Register</v-btn>
              </div>
            </v-col>
            <v-col md="auto">
              <div class="my-2">
                <v-btn v-on:click="resetPassword=true" small>Reset Password</v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
    </v-container>
  </div>
</template>

<script>
import AuthService from '@/api-services/auth.service.js'
import CampService from '@/api-services/camp.service.js'
import Register from '@/components/Register.vue'
import Login from '@/components/Login.vue'
import ResetPasswordEmail from '@/components/ResetPasswordEmail.vue'
export default {
  name: 'Account',
  components: {
    'Register': Register,
    'Login': Login,
    'ResetPasswordEmail': ResetPasswordEmail
  },
  props: ['register', 'isManager'],
  data () {
    return {
      loginType: null,
      username: '',
      password: '',
      loginMsg: "",
      t: this.$cookies.get('cit'),
      user: {
        FirstName: null,
        LastName: null,
        email: null,
        password: null,
        UserType: 'parent'
      },
      camp: {},
      width: '30%',
      acceptTerms: false,
      resetPassword: false,
      resetPasswordEmailSent: false

    }
  },
  mounted () {
    if(this.t) {
      AuthService.getUser(this.t).then(user => { 
        this.$store.commit('updateLoggedIn', true)
        this.user = user.data
        let newRoute = user.data.userType === 'camp' ? '/camp' : '/parent'
        this.$router.push(newRoute)
      })
    } else {
      console.log("user must login")
    }
  },
  methods: {
    async login() {
      let login =  AuthService.login(this.username, this.password);
      await login.then(result => {
        console.log("logged in", result.data)
        if(result !== 'Invalid credentials') {
          this.$store.commit('updateT', result.data.split('|')[1])
          this.$cookies.set("cit", result.data.split('|')[1] ,'3d', null, null, null, "Strict")
          this.$cookies.set("rft", result.data.split('|')[0] , '3d', null, null, null, "Strict")
           AuthService.getUser().then(user => { 
            this.user = user.data
            this.$store.commit('updateUserId', user.data.id)
            this.$store.commit('updateLoggedIn', true)
            if(user.data.userType === 'camp') {
              CampService.getAllUsersCamps(user.data.id)
              .then(r => {
                console.log("got the users camps ", r.data)
                this.$store.commit('updateCampId', r.data[0].campId)
                this.$store.commit('updateCampName', r.data[0].campName)
                console.log("getting campId", this.$store.state.campId)
                localStorage.setItem("myCamp", JSON.stringify(r.data))
              })
            }
            let newRoute = user.data.userType === 'camp' ? '/camp' : '/parent'
            this.$router.push(newRoute)
          })
        } else {
          this.$store.commit('updateLoggedIn', false)
          this.loginMsg = "Invalid Login"
        }
      });
    },
    async RegisterAccount() {
      let register = AuthService.register(this.user, this.camp);
      await register.then(() =>{
        this.username = this.user.email
        this.password = this.user.password
        this.$emit('toggle-register', false)
        this.login()
      })
    },
    updateUserType(newVal) {
      this.user.UserType = newVal
    },
    async resetPasswordEmail(email) {
      console.log("email", email)
      await AuthService.resetPasswordEmail(email)
      this.resetPasswordEmailSent = true
      this.resetPassword = false
      //need to make this a nice message and route to home/login
      
    },
    closeResetPassword() {
      this.resetPasswordEmailSent = false
      this.$emit('keep-modal-open', false)
    }
  },
}
</script>

<style scoped>
  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)  {
    background-color: '#53812B !important';
    color: '#ffffff !important';
  }
</style>
