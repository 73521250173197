<template>
  <v-container>
      <v-row>
        <v-col >
          <v-form>
            <v-text-field outline 
              label="Email" type="text" v-model="email"
            ></v-text-field>
            <v-btn @click="$emit('goBack')" class="primary mr-2">Back to Login</v-btn>
            <v-btn @click="$emit('resetPasswordEmail', email)" class="primary ml-2">Send Email</v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'ResetPasswordEmail',
  data () {
    return {
      email: "",
      emailSent: false
    }
  },
}
</script>