<template>
  <v-container>
      <v-row>
        <v-col >
          <v-form>
            <p>{{loginMsg}} </p>
            <v-text-field outline 
              label="Username" type="text" v-model="localUsername"
              @keyup="$emit('updateusername', username);"
            ></v-text-field>
            <v-text-field outline hide-details label="Password" type="password" v-model="localPassword"  
              @keyup="$emit('updatepassword', password);"
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'Login',
  
  props: ['username', 'password', 'loginMsg'],
  data () {
    return {
      
    }
  },
  computed: {
      localUsername: {
          get() {
            return this.username
          },
          set(username) {
            this.$emit('updateusername', username)
          }
      },
      localPassword: {
          get() {
            return this.password
          },
          set(password) {
            this.$emit('updatepassword', password)
          }
      }
  },
}
</script>